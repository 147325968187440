@if (spaBooking(); as spaBooking) {
  @if (spaBooking.items.length > 1) {
    <div appAutoClose="always" class="relative mt-3 basis-1/2 h-24">
      <button type="button"
              aria-labelledby="spaItemChoice"
              class="bg-gray-100 py-5 px-4 pr-10 md:px-7 hover:bg-gray-200 duration-200 cursor-pointer relative w-full rounded-md text-left text-gray-900 focus:outline-none">
        <div class="flex items-center">
          <div class="w-12">
            <img ngSrc="assets/icons/gray/spa.svg" width="60" height="60" alt="{{ selectedSpaItem()?.label }}"
                 class="h-9 w-9 flex-shrink-0 m-auto">
          </div>
          <div class="truncate text-gray-500 font-semibold text-lg ml-4 md:ml-6">
            <p id="spaItemChoice" class="font-semibold truncate text-xs">{{ 'yourPass' | translate }}</p>
            {{ selectedSpaItem()?.label }}
          </div>
        </div>
        <div class="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-6">
          <img ngSrc="assets/icons/gray/down.svg" width="60" height="60" alt="" class="h-4 w-4">
        </div>
      </button>
      <ul class="hidden absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg
        ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
        @for (item of spaBooking.items; track item.erpId; let first = $first) {
          <li class="text-gray-900 hover:bg-gray-100 relative select-none md:px-5 cursor-pointer"
              [attr.aria-selected]="selectedSpaItem()?.erpId === item.erpId">
            <button class="flex items-center py-4 px-3 w-full" (click)="selectSpaItem(item)">
              <span [ngClass]="selectedSpaItem()?.erpId === item.erpId ? 'font-semibold' : 'font-normal'"
                    class="text-lg text-gray-500 ml-3 block truncate">
                {{ item.label }}
              </span>
            </button>
          </li>
        }
      </ul>
    </div>
  }
}
@if (selectedSpaItem(); as selectedSpaItem) {
  <div class="flex flex-col gap-12 md:flex-row place-content-between relative">
    <div class="grow">
      <div class="h-20 flex items-center border-b border-gray-200 gap-4 mb-5">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="fill-100ciels-300 h-10 w-10">
          <path
            d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"/>
        </svg>
        <p class="text-xl text-100ciels-300 font-semibold">{{ 'nextSessionTitle' | translate }}</p>
      </div>
      @if (updatePlanningProgress()) {
        <div class='w-full'>
          <div class='h-1 w-full bg-gray-300 overflow-hidden'>
            <div class='progress w-full h-full bg-100ciels-300 left-right'></div>
          </div>
        </div>
      } @else {
        @if (availableDays().length) {
          <app-date-picker [customs]="availableDays()" (selectedDataChange)="selectDay($event)"
                           [notBefore]="selectedPeriod.from" [notAfter]="selectedPeriod.to"
                           defaultClasses="line-through"
          ></app-date-picker>
        } @else {
          <p class="text-gray-500 font-semibold">{{ 'noSpaAvailabilities' | translate }}
            <a href="mailto:{{spaEmail()}}" class="font-bold text-100ciels-300">{{ spaEmail() }}</a>.</p>
        }
      }
    </div>
    @if (!updatePlanningProgress()) {
      @if (availableSlots(); as slots) {
        <div>
          <div class="h-20 flex items-center border-b border-gray-200 gap-4 mb-5">
            <p class="text-gray-500 font-semibold">{{ 'selectAnHour' | translate }}</p>
          </div>
          <div class="h-80 overflow-y-auto flex flex-col p-4 gap-4">
            @for (slot of slots; track slot) {
              <button (click)="selectSlot(slot)" tabindex="0" onKeyUp (keyup.enter)="emulateClick($event)"
                      [disabled]="selectSlotProgress()"
                      class="flex flex-row cursor-pointer w-100 text-gray-800 text-center font-semibold
                    border-2 border-gray-200 rounded-lg place-content-between p-4 hover:border-100ciels-300
                    hover:bg-100ciels-300 hover:text-white"
                      [ngClass]="{
                    'border-100ciels-300 bg-100ciels-300 text-white': selectedSlot() === slot,
                    'disabled:bg-gray-300 disabled:text-white': selectedSlot() !== slot
                    }">
                <p class="grow w-8/12"> {{ slot }} </p>
                @if (selectSlotProgress() && selectedSlot() === slot) {
                  <svg class="animate-spin h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg"
                       fill="none"
                       viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                }
              </button>
            }
          </div>
        </div>
      }
    }
    @if (actualSlot(); as slot) {
      <div class="absolute left-2 right-2 bottom-2 md:top-12 md:bottom-12 md:left-12 md:right-12 m-auto">
        <div class="shadow-2xl rounded-xl bg-white p-4">
          <div class="h-16 flex items-center border-b border-gray-200 gap-4 mb-5 flex-row place-content-between">
            <p class="text-gray-500 font-semibold">{{ 'confirmation' | translate }}</p>
            <button class="pr-4 text-lg font-bold" (click)="actualSlot.set(null)">x</button>
          </div>
          <p class="font-semibold text-gray-800 mb-6">{{ 'reservationFor' | translate }} :</p>
          <div class="flex gap-3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-10 h-10 fill-gray-500">
              <path
                d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"/>
            </svg>
            <p><span class="font-semibold">{{ selectedSpaItem?.label }}</span>
              <br>
              {{ selectedDay()?.date | date }}
              <br>
              {{ 'at' | translate }} {{ slot }}
            </p>
          </div>
          <button class="px-6 gap-2 w-full mt-4 py-3 main-btn text-center flex items-center justify-center"
                  appHoverGradient [appProgressClick]="planSpa">
            {{ 'validate' | translate }}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-5 h-5 fill-white">
              <path
                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
            </svg>
          </button>
        </div>
      </div>
    }
  </div>
} @else {
  <div class='w-full mt-14 h-12'>
    <div class='h-1.5 w-full bg-gray-300 overflow-hidden'>
      <div class='progress w-full h-full bg-100ciels-300 left-right'></div>
    </div>
  </div>
}
